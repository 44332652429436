import { Preferences } from '@capacitor/preferences'
import eventList from '@/sync/eventList'
import { logger } from '@/monitoring'
import { lastUtils } from '@last/core'
import { v4 as uuid } from 'uuid'
import { useAuthStore } from '@/store/auth'
import { useTabsStore } from '@/store/tabs'
import { useReservationsStore } from '@/store/reservations'
import { useTillStore } from '@/store/till'
import { usePromotionsStore } from '@/store/promotions'
import { useDeliveryCompaniesStore } from '@/store/deliveryCompanies'
import { useConfigStore } from '@/store/config'
import { postEvent, sync } from '@/api/sync'
import { RemoteEvent, Event, EventName } from '@/types/events'

let events: Event[] = []
let pendingRemote: RemoteEvent[] = []
let playedEventIds: number[] = []
let observers: (() => void)[] = []

let sendingEvents = false
let resyncing = false
let currentEventId = 0
let syncErrors = 0
let pendingResync = false

async function storeEvents() {
  const config = useConfigStore()
  if (config.demoMode) return
  return Preferences.set({ key: 'events', value: JSON.stringify(events) })
}

function appendPlayedEventId(id: number) {
  playedEventIds.push(id)
}

async function loadPendingEvents() {
  const data = await Preferences.get({ key: 'events' })
  if (data.value) {
    events = JSON.parse(data.value)
  }
}

async function sendEvents() {
  if (sendingEvents) return
  sendingEvents = true
  while (events.length > 0) {
    const event = events[0]
    try {
      const response = await postEvent(event)
      appendPlayedEventId(response.id)
      events.shift()
      await storeEvents()
    } catch (error: unknown) {
      if (!error) break
      if (error instanceof Error && error.name === 'NetworkError') {
        logger.info('Connection error while sending events')
      } else {
        logger.warn('Send events error', error)
      }
      await lastUtils.sleep(5_000)
    }
  }
  sendingEvents = false
  pendingRemote.forEach(playRemote)
  pendingRemote = []
  observers.forEach(callback => callback())
  observers = []
  if (pendingResync) {
    resync()
  }
}

function hasEvent(eventId: number) {
  return playedEventIds.includes(eventId)
}

async function resync() {
  logger.debug('resync')
  const auth = useAuthStore()
  if (!auth.isAuthenticated || !auth.locationId) return
  if (sendingEvents) {
    pendingResync = true
    return
  }
  if (resyncing) return
  resyncing = true
  try {
    const data = await sync(
      currentEventId > 0 && syncErrors < 3 ? currentEventId : undefined
    )
    const events = data.events
    const tabs = data.tabs
    const virtualBrandsClosingTimes = data.virtualBrandsClosingTimes

    resyncing = false
    if (events) {
      events.forEach(playRemote)
    } else if (tabs) {
      const reservations = data.reservations
      const startedShifts = data.startedShifts
      const startedShiftsWithCashAmount = data.startedShiftsWithCashAmount
      const shiftsEnabled = data.shiftsEnabled
      const tabPromotions = data.tabPromotions
      const tabsStore = useTabsStore()
      const reservationsStore = useReservationsStore()
      const till = useTillStore()
      const promotions = usePromotionsStore()

      tabsStore.refreshCurrentTabs(tabs)
      reservationsStore.refreshReservations(reservations)
      till.refreshStartedShifts(startedShifts)
      till.refreshStartedShiftsWithCashAmount(startedShiftsWithCashAmount)
      till.refreshShiftsEnabled(shiftsEnabled)
      promotions.refreshTabPromotions(tabPromotions)

      currentEventId = data.currentEventId
    }
    if (virtualBrandsClosingTimes) {
      const deliveryCompanies = useDeliveryCompaniesStore()
      deliveryCompanies.refreshClosingTimes(virtualBrandsClosingTimes)
    }
    pendingRemote.forEach(playRemote)
    pendingRemote = []
    pendingResync = false
    syncErrors = 0
  } catch (error: any) {
    if (error.name !== 'NetworkError') syncErrors += 1
    resyncing = false
    if (error.request || error.response) {
      logger.info('Connection error on sync', error)
    } else {
      logger.error('Resync error', error)
    }
    setTimeout(resync, 5_000)
  }
}

function deepClone(data: any) {
  return JSON.parse(JSON.stringify(data))
}

function play(event: Event, local: boolean) {
  const data = deepClone(event.data)
  if (event.name in eventList) {
    eventList[event.name](data, local)
  } else {
    logger.error("Can't find event " + event.name)
  }
}

function playRemote(event: RemoteEvent) {
  logger.debug('playRemote', event)
  if (event.id <= currentEventId) return
  if (sendingEvents || resyncing) {
    pendingRemote.push(event)
    return
  }
  if (event.id > currentEventId + 1) {
    resync()
    return
  }
  if (!hasEvent(event.id)) {
    play(event, false)
  }
  playedEventIds = playedEventIds.filter(id => id > event.id)
  currentEventId = event.id
}

function record(eventName: EventName, data: any) {
  const config = useConfigStore()
  const auth = useAuthStore()
  const event = {
    uuid: uuid(),
    deviceId: config.device.id,
    name: eventName,
    timestamp: Date.now(),
    employeeId: auth.currentEmployeeId,
    data
  }
  play(event, true)
  logger.debug('recordEvent', event)
  events.push(event)
  storeEvents().then(sendEvents)
}

function observeEnd(callback: () => void) {
  if (events.length > 0) {
    observers.push(callback)
  } else {
    callback()
  }
}

function isSendingEvents() {
  return sendingEvents
}

function isNew(eventId: number) {
  return eventId > (playedEventIds.slice(-1)[0] || 0)
}

function isInitialized() {
  return currentEventId > 0
}

loadPendingEvents().then(() => sendEvents())

export default {
  record,
  isSendingEvents,
  hasEvent,
  isNew,
  playRemote,
  resync,
  isInitialized,
  observeEnd
}
