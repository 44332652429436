<template>
  <div class="flex flex-row">
    <div
      v-if="showFloorplanButton"
      class="px-4 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="{
        'bg-v-300': selectedTab === 'floorplan'
      }"
      @click="emit('update:selectedTab', 'floorplan')"
    >
      <icon size="medium" name="floorplan" />
    </div>
    <div
      class="px-4 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="{
        'bg-v-300': selectedTab === 'delivery'
      }"
      @click="emit('update:selectedTab', 'delivery')"
    >
      <icon size="medium" name="delivery" />
    </div>
    <div
      class="px-4 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="{
        'bg-v-300': selectedTab === 'takeAway'
      }"
      @click="emit('update:selectedTab', 'takeAway')"
    >
      <icon size="medium" name="take-away" />
    </div>
    <div
      class="px-4 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="{
        'bg-v-300': selectedTab === 'all'
      }"
      @click="emit('update:selectedTab', 'all')"
    >
      <icon size="medium" name="tabs" />
    </div>
    <div
      v-if="hasFastPOS"
      class="px-4 sm:px-6 py-2 rounded-full transition-colors cursor-pointer"
      :class="[selectedTab === 'fast' ? 'bg-y-500 text-n-0' : 'text-y-500']"
      @click="useFastPOS().loadFastPOS()"
    >
      <icon size="medium" name="lightning" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computedAsync } from '@vueuse/core'
import { defineProps } from 'vue'

import { Icon, useTracker } from '@last/core-ui/paprika'

import { useFastPOS } from '@/store/fast-pos'

type Props = {
  selectedTab?: string | null
  showFloorplanButton?: boolean
}

withDefaults(defineProps<Props>(), {
  selectedTab: 'floorplan',
  showFloorplanButton: true
})

const emit = defineEmits(['update:selectedTab'])

const tracker = useTracker()

const hasFastPOS = computedAsync(async () => {
  return await tracker.hasFeatureFlag('fastpos')
}, false)
</script>
