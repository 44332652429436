<template>
  <div class="w-full relative" :class="disabledClasses">
    <l-dropdown
      v-model:open="selectorOpen"
      v-model:partially-selected-values="partiallySelectedValues"
      v-model="selectedArray"
      :allow-remove="allowRemove"
      :multiselect="multiselect"
      :options="options"
      :size="size"
      :block-hide-on-click="blockHideOnClick"
      @update:model-value="selectOption"
    >
      <template #content>
        <slot name="content" :options="options"></slot>
      </template>
      <div
        ref="selector"
        id="selector"
        tabindex="0"
        class="w-full leading-tight outline-none border border-transparent flex justify-between items-center cursor-pointer"
        :class="[
          ...errorClasses,
          ...focusClasses,
          ...backgroundColors,
          ...sizeClasses,
          paddingClasses,
          size === 'x-small' ? 'pr-2 rounded-[0.25rem]' : 'pr-3 rounded-xl'
        ]"
        @click="openOrCloseSelector"
      >
        <div class="flex-1 min-w-0 flex items-center">
          <icon
            v-if="leftIcon"
            :name="leftIcon"
            class="mr-4 text-n-200 dark:text-n-0"
          />
          <div class="flex items-center w-full">
            <div
              v-if="isColor"
              :style="{ background: text }"
              class="mx-6 my-2 w-full cursor-pointer rounded"
            />
            <div v-else-if="text" class="truncate" :class="textColors">
              {{ text }}
            </div>
            <p v-else class="text-n-200 dark:text-n-300">
              {{ props.placeholder }}
            </p>
          </div>
        </div>
        <div class="flex items-center">
          <icon
            v-if="rightIcon"
            :name="rightIcon"
            class="mr-4 text-n-200 dark:text-n-0"
          />
          <icon
            v-if="!canRemoveSelection"
            name="arrow-down"
            :size="size"
            class="transition-all duration-500 text-v-500 dark:text-n-100"
            :class="[
              selectorOpen ? 'rotate-180' : '',
              size === 'x-small' && 'ml-2'
            ]"
          />
          <icon
            v-else
            name="close"
            class="transition-all duration-500"
            :class="[...textColors]"
            @click.stop="clearSelection"
          />
        </div>
      </div>
    </l-dropdown>
  </div>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'
import { LDropdown, Icon } from '@last/core-ui/paprika'
import type { Option } from '@last/core-ui/paprika/components/dropdown/types'
import type { Size } from '@last/core-ui/paprika/components/types'
import { useI18n } from 'vue-i18n'
import {
  useErrorClasses,
  useSizeClasses,
  usePaddingClasses,
  useFocusClasses,
  useDisabledClasses,
  useBackgroundColorsClasses,
  useTextColorsClasses
} from '@last/core-ui/paprika/components/classes'

const { t } = useI18n()

type Props = {
  defaultValue?: string | string[] | null
  options: Option[]
  placeholder?: string
  /** Tells the component to always show the placeholder text instead of the selected value */
  isPlaceholderFixed?: boolean
  icon?: string
  iconPosition?: string
  size?: Size
  allowRemove?: boolean
  error?: boolean
  multiselect?: boolean
  disabled?: boolean
  search?: boolean
  /** Blocks the select from being closed on click if true */
  blockHideOnClick?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  defaultValue: null,
  options: () => [],
  placeholder: '',
  isPlaceholderFixed: false,
  iconPosition: 'right',
  size: 'small',
  icon: undefined,
  allowRemove: false,
  error: false,
  multiselect: false,
  disabled: false,
  search: false,
  blockHideOnClick: false
})

const model = defineModel<number | string | string[] | null>({
  default: null
})

const partiallySelectedValues = defineModel<(string | number)[]>(
  'partiallySelectedValues',
  { default: [] }
)

const selectorRef = useTemplateRef<HTMLElement>('selector')
const selectorOpen = defineModel<boolean>('selectorOpen', { default: false })

function closeSelector() {
  selectorRef?.value?.blur()
  selectorOpen.value = false
}

function openSelector() {
  selectorRef?.value?.focus()
  selectorOpen.value = true
}

function openOrCloseSelector() {
  return selectorOpen.value ? closeSelector() : openSelector()
}

function clearSelection() {
  model.value = null
}

function selectOption(newSelection: string[]) {
  const value = newSelection
    ? props.multiselect
      ? newSelection
      : newSelection[0]
    : null
  model.value = value
  if (!props.multiselect) closeSelector()
}

const selectedArray = computed(() => {
  if (!model.value) return []
  return Array.isArray(model.value) ? model.value : [model.value]
})

const canRemoveSelection = computed(() => {
  return props.allowRemove && selectedArray.value?.length > 0
})

const labels = computed<Record<string, string>>(() => {
  const result: Record<string, string> = {}
  const queue = [...props.options]
  while (queue.length > 0) {
    const option = queue.shift()
    if (!option) continue
    if (option.children) {
      queue.push(...option.children)
    }
    result[option.value] = option.label
  }
  return result
})

const text = computed(() => {
  if (props.isPlaceholderFixed) return props.placeholder
  if (!model.value) return

  if (!Array.isArray(model.value)) return labels.value[model.value]

  const items: string[] = []
  for (const option of props.options) {
    if (model.value.includes(option.value)) {
      items.push(labels.value[option.value])
    } else if (option.children) {
      const allChildrenSelected = option.children.every(child =>
        model.value!.includes(child.value)
      )
      if (allChildrenSelected) {
        items.push(option.label + `(${t('core.all')})`)
      } else {
        const childrenItems: string[] = []
        for (const child of option.children) {
          if (model.value.includes(child.value)) {
            childrenItems.push(child.label)
          }
        }
        if (childrenItems.length)
          items.push(option.label + `(${childrenItems.join(', ')})`)
      }
    }
  }
  return items.join(', ')
})

const isColor = computed(() => {
  if (!text.value) return false
  return text.value.match(`#([0-9]|[A-F]){6}`)
})

const leftIcon = computed(() => {
  return props.iconPosition === 'left' ? props.icon : undefined
})

const rightIcon = computed(() => {
  return props.iconPosition === 'right' ? props.icon : undefined
})

const disabledClasses = computed(() => useDisabledClasses(props.disabled))
const errorClasses = computed(() => useErrorClasses(props.error))
const sizeClasses = computed(() => useSizeClasses(props.size))
const paddingClasses = computed(() => usePaddingClasses(props.size))
const focusClasses = computed(() => useFocusClasses())
const backgroundColors = computed(() => useBackgroundColorsClasses())
const textColors = computed(() => useTextColorsClasses())
</script>
