import TicketPrinter from '@/integrations/printer/ticketPrinter'
import App from '@/app'
import { normalizeTab } from '@/normalizr'
import i18n from '@/i18n'
import moment from 'moment'
import { useTabsStore } from '@/store/tabs'
import { useAuthStore } from '@/store/auth'
import { usePromotionsStore } from '@/store/promotions'
import { useReservationsStore } from '@/store/reservations'
import { useConfigStore } from '@/store/config'
import { useDeliveryCompaniesStore } from '@/store/deliveryCompanies'
import { useTillStore } from '@/store/till'
import { useNotifications } from '@/composables/useNotifications'
import localDb from '@/localDb'
import { storeToRefs } from 'pinia'
import {
  Tab,
  Product,
  CustomerInfo,
  Discount,
  Courier,
  Bill,
  Payment,
  KitchenOrder,
  Reservation,
  TabPromotion,
  TabProduct,
  PickupType
} from '@/types'
import { Shipment } from '@/types/shipment'
import { DeliveryOrder } from '@/types/deliveryOrder'
import { router } from '@/router'

const events = {
  tabOpened(tab: Tab) {
    const tabs = useTabsStore()
    const shared = tab.shared || []
    const newTab = {
      ...tab,
      shared: shared.map<string>(product => product.id),
      seats: Array.from({ length: tab.seats }, () => []),
      bills: [],
      kitchenOrders: []
    }
    const products = shared.reduce<Record<string, Product>>((res, product) => {
      res[product.id] = product
      return res
    }, {})
    tabs.products = { ...tabs.products, ...products }
    tabs.tabs[newTab.id] = newTab
    localDb.setItem('tabs:tabs', newTab.id, newTab)
  },
  tabOpenedWithCustomer({ tab }: { tab: Tab }) {
    this.tabOpened(tab)
  },
  tabCustomerUpdated({
    tabId,
    customerId
  }: {
    tabId: string
    customerId: string
  }) {
    const tabs = useTabsStore()
    tabs.tabs[tabId]['customerId'] = customerId
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  },
  tabCustomerInfoUpdated({
    tabId,
    customerId,
    customerInfo
  }: {
    tabId: string
    customerId: string
    customerInfo: CustomerInfo
  }) {
    const tabs = useTabsStore()
    tabs.tabs[tabId]['customerId'] = customerId
    tabs.tabs[tabId]['customerInfo'] = customerInfo
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  },
  productAdded({
    tabId,
    seat,
    product
  }: {
    tabId: string
    seat: number | null
    product: TabProduct
  }) {
    const tabs = useTabsStore()
    if (seat != null) {
      tabs.products[product.id] = product
      tabs.tabs[tabId].seats[seat].push(product.id)
    } else {
      tabs.products[product.id] = product
      tabs.tabs[tabId].shared.push(product.id)
    }
    localDb.setItem('tabs:products', product.id, product).then(() => {
      localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
    })
  },
  productMoved({
    tabId,
    seat,
    product
  }: {
    tabId: string
    seat: number
    product: Product
  }) {
    const tabs = useTabsStore()
    tabs.tabs[tabId].shared = tabs.tabs[tabId].shared.filter(
      productId => productId != product.id
    )
    tabs.tabs[tabId].seats = tabs.tabs[tabId].seats.map(s =>
      s.filter(productId => productId != product.id)
    )
    if (seat != null) {
      tabs.tabs[tabId].seats[seat].push(product.id)
    } else {
      tabs.tabs[tabId].shared.push(product.id)
    }
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  },
  productTabMoved({
    fromTabId,
    toTabId,
    product
  }: {
    fromTabId: string
    toTabId: string
    product: Product
  }) {
    const tabs = useTabsStore()
    tabs.products[product.id].tab = toTabId
    tabs.tabs[fromTabId].shared = tabs.tabs[fromTabId].shared.filter(
      productId => productId != product.id
    )
    tabs.tabs[fromTabId].seats = tabs.tabs[fromTabId].seats.map<string[]>(
      seat => seat.filter(productId => productId != product.id)
    )
    tabs.tabs[toTabId].shared.push(product.id)
    if (tabs.tabs[toTabId].seats.length === 0) {
      tabs.tabs[toTabId].seats.push([]) //ensure at least one seat per tab
    }

    localDb.setItem('tabs:tabs', fromTabId, tabs.tabs[fromTabId]).then(() => {
      localDb.setItem('tabs:tabs', toTabId, tabs.tabs[toTabId])
    })
  },
  productModifiersUpdated({
    productId,
    modifiers,
    comments,
    productPricing
  }: {
    productId: string
    modifiers: any[]
    comments: string
    productPricing: { fullPrice: number; finalPrice: number }
  }) {
    const tabs = useTabsStore()
    tabs.products[productId].modifiers = modifiers
    tabs.products[productId].comments = comments
    tabs.products[productId].fullPrice = productPricing.fullPrice
    tabs.products[productId].finalPrice = productPricing.finalPrice
    localDb.setItem('tabs:products', productId, tabs.products[productId])
  },
  productQuantityUpdated({
    productId,
    quantity
  }: {
    productId: string
    quantity: number
  }) {
    const tabs = useTabsStore()
    tabs.products[productId].quantity = quantity
    localDb.setItem('tabs:products', productId, tabs.products[productId])
  },
  productDiscountUpdated({
    productId,
    discount,
    productPricing
  }: {
    productId: string
    discount: Discount
    productPricing: { fullPrice: number; finalPrice: number }
  }) {
    const tabs = useTabsStore()
    tabs.products[productId] = {
      ...tabs.products[productId],
      ...discount,
      ...productPricing
    }
    localDb.setItem('tabs:products', productId, tabs.products[productId])
  },
  productRemoved(productId: string) {
    const tabs = useTabsStore()
    const tabId = tabs.products[productId].tab
    delete tabs.products[productId]
    tabs.tabs[tabId].shared = tabs.tabs[tabId].shared.filter(
      p => p != productId
    )
    tabs.tabs[tabId].seats = tabs.tabs[tabId].seats.map(seat =>
      seat.filter(p => p != productId)
    )
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId]).then(() => {
      localDb.removeItem('tabs:products', productId)
    })
  },
  deliveryOrderStatusUpdated({
    tabId,
    newStatus,
    courier,
    date
  }: {
    tabId: string
    newStatus: string
    courier: Courier
    date: Date
  }) {
    const tabs = useTabsStore()
    tabs.tabs[tabId].deliveryOrder.status = newStatus
    tabs.tabs[tabId].deliveryOrder.deliveryOrderStatuses.push({
      status: newStatus,
      tabId,
      creationTime: date
    })
    if (courier) {
      tabs.tabs[tabId].deliveryOrder.courierName = courier.name
      tabs.tabs[tabId].deliveryOrder.courierPhoneNumber = courier.phoneNumber
      tabs.tabs[tabId].deliveryOrder.courierId = courier.id
    }
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  },
  deliveryOrderCourierPhoneUpdated({
    tabId,
    courierPhoneNumber
  }: {
    tabId: string
    courierPhoneNumber: string
  }) {
    const tabs = useTabsStore()
    tabs.tabs[tabId].deliveryOrder.courierPhoneNumber = courierPhoneNumber
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  },
  deliveryOrderShipmentUpdated({
    tabId,
    shipment
  }: {
    tabId: string
    shipment: Shipment
  }) {
    const tabs = useTabsStore()
    if (tabs.tabs[tabId].deliveryOrder) {
      tabs.tabs[tabId].deliveryOrder['shipmentId'] = shipment.id
      tabs.tabs[tabId].deliveryOrder['shipment'] = shipment
      tabs.tabs[tabId].deliveryOrder['shipmentCancelled'] = false
      localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
    }
  },
  deliveryOrderAddressUpdated({
    tabId,
    address,
    latitude,
    longitude,
    postalCode
  }: {
    tabId: string
    address: string
    latitude: number
    longitude: number
    postalCode: string
  }) {
    const tabs = useTabsStore()
    if (tabs.tabs[tabId].deliveryOrder) {
      tabs.tabs[tabId].deliveryOrder['address'] = address
      tabs.tabs[tabId].deliveryOrder['latitude'] = latitude
      tabs.tabs[tabId].deliveryOrder['longitude'] = longitude
      tabs.tabs[tabId].deliveryOrder['postalCode'] = postalCode
      localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
    }
  },
  preferredPaymentMethodSelected({
    tabId,
    preferredPaymentMethod
  }: {
    tabId: string
    preferredPaymentMethod: string
  }) {
    const tabs = useTabsStore()
    if (tabs.tabs[tabId] && tabs.tabs[tabId].deliveryOrder) {
      tabs.tabs[tabId].deliveryOrder = {
        ...tabs.tabs[tabId].deliveryOrder,
        preferredPaymentMethod
      }

      localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])

      const tabBills = tabs.tabs[tabId].bills.map(billId => tabs.bills[billId])
      tabBills.forEach(bill => {
        bill = {
          ...bill,
          preferredPaymentMethod
        }
        localDb.setItem('tabs:bills', bill.id, tabs.bills[bill.id])
      })
    }
  },
  seatRemoved({
    tabId,
    selectedSeatIndex
  }: {
    tabId: string
    selectedSeatIndex: number
  }) {
    const tabsStore = useTabsStore()
    const { tabs } = storeToRefs(tabsStore)
    tabs.value[tabId].seats.splice(selectedSeatIndex, 1)
    localDb.setItem('tabs:tabs', tabId, tabs.value[tabId])
  },
  billAdded({ tabId, bill }: { tabId: string; bill: Bill }) {
    const tabs = useTabsStore()
    tabs.bills[bill.id] = bill
    tabs.tabs[tabId].bills.push(bill.id)
    localDb.setItem('tabs:bills', bill.id, bill).then(() => {
      localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
    })
  },
  billRemoved({ tabId, billId }: { tabId: string; billId: string }) {
    const tabs = useTabsStore()
    delete tabs.bills[billId]
    tabs.tabs[tabId].bills = tabs.tabs[tabId].bills.filter(id => id != billId)
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId]).then(() => {
      localDb.removeItem('tabs:bills', billId)
    })
  },
  paymentAdded({ billId, payment }: { billId: string; payment: Payment }) {
    const tabs = useTabsStore()
    tabs.payments[payment.id] = payment
    tabs.bills[billId].payments.push(payment.id)
    localDb.setItem('tabs:payments', payment.id, payment).then(() => {
      localDb.setItem('tabs:bills', billId, tabs.bills[billId])
    })
    const config = useConfigStore()
    if (!(config.device.mode === 'master')) return
    if (!config.config.autoprintReceipts) return
    if (payment.type === 'dataphone' && payment.metadata?.receipt)
      TicketPrinter.printBankReceipt(payment)
  },
  paymentDeleted({ billId, paymentId }: { billId: string; paymentId: string }) {
    const tabs = useTabsStore()
    delete tabs.payments[paymentId]
    tabs.bills[billId].payments = tabs.bills[billId].payments.filter(
      id => id != paymentId
    )
    localDb.setItem('tabs:bills', billId, tabs.bills[billId]).then(() => {
      localDb.removeItem('tabs:payments', paymentId)
    })
  },
  tabClosed({ tabId, closeTime }: { tabId: string; closeTime: Date }) {
    const tabs = useTabsStore()
    tabs.tabs[tabId].open = false
    tabs.tabs[tabId].closeTime = closeTime
    if (!tabs.tabs[tabId].activationTime) {
      tabs.tabs[tabId].activationTime = closeTime
    }
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  },
  tabReopened({ tabId }: { tabId: string }) {
    const tabs = useTabsStore()
    tabs.tabs[tabId].open = true
    tabs.tabs[tabId].closeTime = null
    tabs.tabs[tabId].closedWithPin = false
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  },
  tabClosedWithInfo({
    tabId,
    showPaidNotification,
    closeTime
  }: {
    tabId: string
    showPaidNotification: boolean
    closeTime: Date
  }) {
    const tabs = useTabsStore()
    const tab = tabs.getTab(tabId)

    if (showPaidNotification) {
      const payments: Payment[] = tab.bills.flatMap(billId =>
        tabs.getBillPayments(billId)
      )
      let icon = 'card'
      if (payments.some(payment => payment.type === 'sunday')) {
        icon = 'sunday-logo'
      }
      App.playSound('payment')

      const { notifyInfo } = useNotifications()
      notifyInfo({
        title: i18n.global.t('notifications.table-already-paid', {
          tableName: tab.tableName
        }),
        icon: icon,
        onAction: () => {
          if (App.isMobile) return
          router.push({
            name: 'checkout',
            params: { tabId }
          })
        }
      })
    }
    tabs.tabs[tabId].open = false
    tabs.tabs[tabId].closeTime = closeTime
    if (!tabs.tabs[tabId].activationTime) {
      tabs.tabs[tabId].activationTime = closeTime
    }
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  },
  kitchenNoteAdded() {
    // NO_OP
  },
  kitchenOrderAdded({ tabId, order }: { tabId: string; order: KitchenOrder }) {
    const tabs = useTabsStore()
    tabs.kitchenOrders[order.id] = order
    tabs.tabs[tabId].kitchenOrders.push(order.id)
    localDb.setItem('tabs:kitchenOrders', order.id, order).then(() => {
      localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
    })
  },
  kitchenOrderVersionAdded({
    orderId,
    version
  }: {
    orderId: string
    version: KitchenOrder
  }) {
    const tabs = useTabsStore()
    tabs.kitchenOrders[orderId].versions.push(version)
    localDb.setItem('tabs:kitchenOrders', orderId, tabs.kitchenOrders[orderId])
  },
  tablesAssigned({
    tabId,
    tables,
    tableName
  }: {
    tabId: string
    tables: string[]
    tableName: string
  }) {
    const tabs = useTabsStore()
    tabs.tabs[tabId].tables = tables
    tabs.tabs[tabId].tableName = tableName
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  },
  courseUpdated({ productId, course }: { productId: string; course: string }) {
    const tabs = useTabsStore()
    tabs.products[productId].course = course
    localDb.setItem('tabs:products', productId, tabs.products[productId])
  },
  seatAdded(tabId: string) {
    const tabs = useTabsStore()
    tabs.tabs[tabId].seats = [...tabs.tabs[tabId].seats, []]
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  },
  kitchenOrderPrinted({
    orderId,
    printedTime
  }: {
    orderId: string
    printedTime: Date
  }) {
    const tabs = useTabsStore()
    if (tabs.kitchenOrders[orderId]) {
      tabs.kitchenOrders[orderId]['printedTime'] = printedTime
      localDb.setItem(
        'tabs:kitchenOrders',
        orderId,
        tabs.kitchenOrders[orderId]
      )
    }
  },
  billPrinted({ billId, printedTime }: { billId: string; printedTime: Date }) {
    const tabs = useTabsStore()
    if (tabs.bills[billId]) {
      tabs.bills[billId].printedTime = printedTime
      localDb.setItem('tabs:bills', billId, tabs.bills[billId])
    }
  },
  deliveriesClosed({
    deliveriesToClose,
    closedUntil
  }: {
    deliveriesToClose: Record<string, string[]>
    closedUntil: Date
  }) {
    const deliveryCompanies = useDeliveryCompaniesStore()
    Object.keys(deliveriesToClose).forEach(brandId => {
      deliveriesToClose[brandId].forEach(company => {
        const targetBrand = deliveryCompanies.virtualBrandsClosingTimes.find(
          brand => brand.id === brandId
        )
        if (targetBrand) {
          const currentClosedUntil =
            targetBrand.externalDeliveryCompaniesClosingTimes[company]
          if (
            currentClosedUntil &&
            moment(currentClosedUntil).isAfter(moment(closedUntil))
          ) {
            return
          }
          targetBrand.externalDeliveryCompaniesClosingTimes[company] =
            closedUntil
        }
      })
    })
  },
  openDeliveries({
    deliveriesToOpen
  }: {
    deliveriesToOpen: Record<string, string[]>
  }) {
    const deliveryCompanies = useDeliveryCompaniesStore()
    Object.keys(deliveriesToOpen).forEach(brandId => {
      deliveriesToOpen[brandId].forEach(async company => {
        const targetBrand = deliveryCompanies.virtualBrandsClosingTimes.find(
          brand => brand.id === brandId
        )
        if (targetBrand) {
          targetBrand.externalDeliveryCompaniesClosingTimes[company] = null
        }
      })
    })
  },
  deliveryCloseFailed({
    brandId,
    company
  }: {
    brandId: string
    company: string
  }) {
    const { notifyError } = useNotifications()
    notifyError({
      title: i18n.global.t('close-delivery.failed-close') + ' ' + company
    })
    const deliveryCompanies = useDeliveryCompaniesStore()
    const targetBrand = deliveryCompanies.virtualBrandsClosingTimes.find(
      brand => brand.id === brandId
    )
    if (targetBrand) {
      targetBrand.externalDeliveryCompaniesClosingTimes[company] = null
    }
  },
  deliveryOpenFailed({
    brandId,
    company,
    closedUntil
  }: {
    brandId: string
    company: string
    closedUntil: Date
  }) {
    const { notifyError } = useNotifications()
    notifyError({
      title: i18n.global.t('close-delivery.failed-open') + ' ' + company
    })
    const deliveryCompanies = useDeliveryCompaniesStore()
    const targetBrand = deliveryCompanies.virtualBrandsClosingTimes.find(
      brand => brand.id === brandId
    )
    if (targetBrand) {
      targetBrand.externalDeliveryCompaniesClosingTimes[company] = closedUntil
    }
  },
  kitchenOrderAddedToPrintQueue(id: string) {
    const config = useConfigStore()
    const tabs = useTabsStore()
    if (config.device.mode === 'master') {
      const order = tabs.kitchenOrders[id]
      TicketPrinter.printKitchenOrder(order)
    }
  },
  paymentAddedToPrintQueue() {
    // NO_OP
  },
  billAddedToPrintQueue(id: string) {
    const config = useConfigStore()
    const tabs = useTabsStore()
    if (config.device.mode === 'master') {
      const bill = tabs.getBillById(id)
      TicketPrinter.printBill(bill)
    }
  },
  pendingBillAddedToPrintQueue(tabId: string) {
    const config = useConfigStore()
    const tabs = useTabsStore()
    if (config.device.mode === 'master') {
      const bill = tabs.getPendingBill(tabId)
      TicketPrinter.printBill(bill, true)
    }
  },
  mergeTabs({
    tabAId,
    tabBId,
    newTab
  }: {
    tabAId: string
    tabBId: string
    newTab: Tab
  }) {
    const tabs = useTabsStore()
    const tabA = tabs.tabs[tabAId]
    const tabB = tabs.tabs[tabBId]
    const mergedTab = {
      ...newTab,
      tables: [...new Set([...tabA.tables, ...tabB.tables])],
      seats: [...tabA.seats, ...tabB.seats],
      shared: [...tabA.shared, ...tabB.shared],
      bills: [...tabA.bills, ...tabB.bills],
      kitchenOrders: [...tabA.kitchenOrders, ...tabB.kitchenOrders]
    }
    tabs.tabs[mergedTab.id] = mergedTab
    localDb.setItem('tabs:tabs', mergedTab.id, mergedTab)
    const oldIds = [tabA.id, tabB.id]
    Object.values(tabs.bills)
      .filter(bill => oldIds.includes(bill.tabId))
      .forEach(bill => (bill.tabId = mergedTab.id))
    Object.values(tabs.kitchenOrders)
      .filter(order => oldIds.includes(order.tabId))
      .forEach(order => (order.tabId = mergedTab.id))
    Object.values(tabs.products)
      .filter(product => oldIds.includes(product.tab))
      .forEach(product => (product.tab = mergedTab.id))
    oldIds.forEach(id => {
      delete tabs.tabs[id]
      localDb.removeItem('tabs:tabs', id)
    })
  },
  cancelTab({ tabId, cancelTime }: { tabId: string; cancelTime: Date }) {
    const tabs = useTabsStore()
    const config = useConfigStore()
    tabs.tabs[tabId].deliveryOrder.cancelTime = cancelTime
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
    if (config.device.mode === 'master') {
      App.playSound('cancel')
      const tab = tabs.getTab(tabId)
      TicketPrinter.printCancelTabTicket(tab)
    }
  },
  turnOpened(tillId: string) {
    const till = useTillStore()
    if (!till.startedShifts.includes(tillId)) {
      till.startedShifts[till.startedShifts.length] = tillId
    }
  },
  newTillShiftCreated(tillId: string) {
    const till = useTillStore()
    if (!till.startedShifts.includes(tillId)) {
      till.startedShifts[till.startedShifts.length] = tillId
    }
  },
  turnClosed(tillId: string) {
    const till = useTillStore()
    till.startedShifts = till.startedShifts.filter(id => id !== tillId)
    till.startedShiftsWithCashAmount = till.startedShiftsWithCashAmount.filter(
      id => id !== tillId
    )
  },
  dayLastTillClosed() {
    // NO_OP
  },
  shiftStarted({ tillId }: { tillId: string }) {
    const till = useTillStore()
    if (!till.startedShifts.includes(tillId)) {
      till.startedShifts[till.startedShifts.length] = tillId
    }
  },
  shiftEnded({ tillId }: { tillId: string }, local: boolean) {
    const till = useTillStore()
    const auth = useAuthStore()
    till.startedShifts = till.startedShifts.filter(id => id !== tillId)
    till.startedShiftsWithCashAmount = till.startedShiftsWithCashAmount.filter(
      id => id !== tillId
    )
    const currentCashTill =
      till.selectedCashTill && tillId === till.selectedCashTill.id
    const currentEmployeeTill =
      auth.currentEmployee && tillId === auth.currentEmployee.tillId

    if (
      (currentCashTill || currentEmployeeTill) &&
      !local &&
      router.currentRoute.value.name !== 'employees'
    ) {
      router.push({ name: 'employees' })
    }
  },
  addMetadataToBills(bills: Bill[]) {
    const tabs = useTabsStore()
    return bills.map(b => {
      if (tabs.billsMetadata[b.id]) {
        return {
          ...b,
          metadata: { ...b.metadata, ...tabs.billsMetadata[b.id] }
        }
      } else {
        return b
      }
    })
  },
  fullOrderAdded(data) {
    const config = useConfigStore()
    const isMaster = config.device.mode === 'master'
    data.bills = events.addMetadataToBills(data.bills)
    const tabData = normalizeTab(data)
    const tabs = useTabsStore()
    const promotions = usePromotionsStore()
    tabs.refreshTab(tabData)
    if (data.promotion) {
      promotions.tabPromotions[data.promotion.tabId] = [
        ...(promotions.tabPromotions[data.promotion.tabId] || []),
        data.promotion
      ]
    }
    if (isMaster && data.activationTime) {
      App.playSound('notification')
      if (
        config.config.enableKitchenOrders &&
        config.config.autoprintKitchenOrders
      ) {
        data.kitchenOrders.forEach(order => {
          TicketPrinter.printKitchenOrder(order)
        })
      }
      if (config.config.autoprintBills) {
        data.bills.forEach(bill => {
          TicketPrinter.printBill(bill)
        })
      }
    }
    if (tabData.tabs) {
      Object.values(tabData.tabs).forEach(tab => {
        if (tab.tableName) {
          const { notifyInfo } = useNotifications()
          notifyInfo({
            title: i18n.global.t('tabs.new-order-in-table', {
              table: tab.tableName
            })
          })
        }
      })
    }
  },
  movementAdded() {
    // NO_OP
  },
  shipmentFailed({
    tabId,
    errorTime,
    errorMessage
  }: {
    tabId: string
    errorTime: Date
    errorMessage: string
  }) {
    App.playSound('alert')
    const tabs = useTabsStore()
    tabs.tabs[tabId].deliveryOrder['errorTime'] = errorTime
    tabs.tabs[tabId].deliveryOrder['errorMessage'] = errorMessage
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  },
  shipmentErrorResolved({ tabId }: { tabId: string }) {
    const tabs = useTabsStore()
    tabs.tabs[tabId].deliveryOrder['errorResolved'] = true
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  },
  billRegenerated({
    tabId,
    oldBillId,
    bill
  }: {
    tabId: string
    oldBillId: string
    bill: Bill
  }) {
    const tabs = useTabsStore()
    if (oldBillId === bill.id) {
      tabs.bills[bill.id] = bill
    } else {
      delete tabs.bills[oldBillId]
      localDb.removeItem('tabs:bills', oldBillId)
      tabs.tabs[tabId].bills = tabs.tabs[tabId].bills.filter(
        id => id != oldBillId
      )
      tabs.bills[bill.id] = bill
      localDb.setItem('tabs:bills', bill.id, bill)
      tabs.tabs[tabId].bills.push(bill.id)
      localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
    }
  },
  billFinalized({ billId, number }: { billId: string; number: number }) {
    const tabs = useTabsStore()
    tabs.bills[billId].number = number
    localDb.setItem('tabs:bills', billId, tabs.bills[billId])
    TicketPrinter.wakeupPrint(billId, 'number')
  },
  billMetadataUpdated({
    billId,
    metadata
  }: {
    billId: string
    metadata: Record<string, any>
  }) {
    const tabs = useTabsStore()
    tabs.billsMetadata[billId] = {
      ...(tabs.billsMetadata[billId] || {}),
      ...metadata
    }
    if (!tabs.bills[billId]) {
      return
    }
    tabs.bills[billId].metadata = {
      ...metadata,
      ...tabs.bills[billId].metadata
    }
    localDb.setItem('tabs:bills', billId, tabs.bills[billId])
    TicketPrinter.wakeupPrint(billId, 'meta')
  },
  customerCreated() {
    // NO_OP
  },
  sendInvoiceEmailRequested() {
    // NO_OP
  },
  customerUpdated() {
    // NO_OP
  },
  tabDetailsUpdated({
    kitchenNote,
    customerNote,
    schedulingTime,
    activationTime,
    customerInfo,
    deliveryOrder,
    id: tabId
  }: {
    kitchenNote: string
    customerNote: string
    schedulingTime: Date
    activationTime: Date
    customerInfo: CustomerInfo
    deliveryOrder: DeliveryOrder
    id: string
  }) {
    const tabs = useTabsStore()
    const oldTab = tabs.tabs[tabId]
    tabs.tabs[tabId] = {
      ...oldTab,
      schedulingTime,
      activationTime,
      customerInfo,
      deliveryOrder,
      kitchenNote,
      customerNote
    }
    tabs.tabs[tabId].bills.forEach(billId => {
      tabs.bills[billId]['schedulingTime'] = schedulingTime
      tabs.bills[billId]['activationTime'] = activationTime
    })
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  },
  billingStarted({ tabId, timestamp }: { tabId: string; timestamp: Date }) {
    const tabs = useTabsStore()
    tabs.tabs[tabId].billingStartedTime = timestamp
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  },
  comboProductsUpdated({
    comboId,
    products,
    productPricing
  }: {
    comboId: string
    products: Product[]
    productPricing: { fullPrice: number; finalPrice: number }
  }) {
    const tabs = useTabsStore()
    tabs.products[comboId].comboProducts = products
    tabs.products[comboId].fullPrice = productPricing.fullPrice
    tabs.products[comboId].finalPrice = productPricing.finalPrice
    localDb.setItem('tabs:products', comboId, tabs.products[comboId])
  },
  updateTabPickupType({
    tabId,
    pickupType
  }: {
    tabId: string
    pickupType: PickupType | undefined
  }) {
    const tabs = useTabsStore()
    tabs.tabs[tabId].pickupType = pickupType
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  },
  updateTabActivationTime({
    tabId,
    activationTime
  }: {
    tabId: string
    activationTime: Date
  }) {
    const tabs = useTabsStore()
    tabs.tabs[tabId]['activationTime'] = activationTime
    const tab = tabs.tabs[tabId]
    tabs.tabs[tabId].bills.forEach(billId => {
      const bill = tabs.bills[billId]
      tabs.bills[billId] = {
        ...bill,
        activationTime,
        schedulingTime: tab?.schedulingTime
      }
    })
    tabs.tabs[tabId].kitchenOrders.forEach(orderId => {
      const order = tabs.kitchenOrders[orderId]
      tabs.kitchenOrders[orderId] = { ...order, activationTime }
    })
    localDb?.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  },
  reservationCreated(reservation: Reservation) {
    const { notifySuccess } = useNotifications()
    notifySuccess({
      title: i18n.global.t('notifications.new-reservation'),
      description: moment(reservation.dateTime).calendar(),
      icon: 'calendar',
      onAction: () => {
        router.push({
          name: 'reservations'
        })
      }
    })
    const reservations = useReservationsStore()
    const { internalSource, ...cleanReservation } = reservation
    reservations.reservations[reservation.id] = cleanReservation
  },
  reservationCancelled({ reservationId }: { reservationId: string }) {
    const reservations = useReservationsStore()
    const reservation = reservations.reservations[reservationId]

    const { notifySuccess } = useNotifications()
    notifySuccess({
      title: i18n.global.t('notifications.cancelled-reservation'),
      description: moment(reservation.dateTime).calendar(),
      icon: 'calendar',
      onAction: () => {
        if (!App.isMobile) {
          router.push({
            name: 'reservations'
          })
        }
      }
    })
    reservations.reservations[reservationId].cancelled = true
  },
  reservationChanged(reservation: Reservation) {
    const reservations = useReservationsStore()
    const { internalSource, ...cleanReservation } = reservation
    reservations.reservations[reservation.id] = {
      ...reservations.reservations[reservation.id],
      ...cleanReservation
    }
  },
  reservationTableChanged({
    reservationId,
    tables
  }: {
    reservationId: string
    tables: string[]
  }) {
    const reservations = useReservationsStore()
    reservations.reservations[reservationId].tables = tables
  },
  tabCodeGenerated({ tabId, code }: { tabId: string; code: string }) {
    const tabs = useTabsStore()
    tabs.tabs[tabId]['code'] = code
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  },
  promotionAddedToTab({ tabPromotion }: { tabPromotion: TabPromotion }) {
    const promotions = usePromotionsStore()
    promotions.tabPromotions[tabPromotion.tabId] = [
      ...(promotions.tabPromotions[tabPromotion.tabId] || []),
      tabPromotion
    ]
  },
  promotionDeletedFromTab({
    tabPromotionId,
    tabId
  }: {
    tabPromotionId: string
    tabId: string
  }) {
    const promotions = usePromotionsStore()
    promotions.tabPromotions[tabId] = promotions.tabPromotions[tabId].filter(
      internalTabPromotion => internalTabPromotion.id !== tabPromotionId
    )
  },
  promotionRedeemed({ promotionId }: { promotionId: string }) {
    const promotions = usePromotionsStore()
    const promotionIndex = promotions.promotions.findIndex(
      promotion => promotion.id === promotionId
    )
    if (promotionIndex != -1) {
      const promotion = promotions.promotions[promotionIndex]
      if (promotion.maxRedemptions) {
        promotion.remainingRedemptions--
      }
      promotions.promotions[promotionIndex] = promotion
    }
  },
  pickupTimeUpdated({
    tabId,
    pickupTime
  }: {
    tabId: string
    pickupTime: Date
  }) {
    const tabs = useTabsStore()
    if (tabs.tabs[tabId].deliveryOrder) {
      tabs.tabs[tabId].deliveryOrder['pickupTime'] = pickupTime
      localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
    }
  },
  deliveryCancelled({ tabId }: { tabId: string }) {
    const tabs = useTabsStore()
    if (tabs.tabs[tabId].deliveryOrder) {
      tabs.tabs[tabId].deliveryOrder['shipmentCancelled'] = true
      tabs.tabs[tabId].deliveryOrder['shipment'] = null
      tabs.tabs[tabId].deliveryOrder['shipmentId'] = null
    }
  },
  terraceSurchargePercentageUpdated({
    tabId,
    newPercentage
  }: {
    tabId: string
    newPercentage: number
  }) {
    const tabs = useTabsStore()
    tabs.tabs[tabId].terraceSurchargePercentage = newPercentage
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  },
  deviceDeleted({ deviceId }: { deviceId: string }) {
    const auth = useAuthStore()
    const config = useConfigStore()
    if (config.device.id === deviceId) {
      auth.logout()
    }
  },
  updateShipmentProvider(shipmentProvider: { provider: string }) {
    const config = useConfigStore()
    config.config.shipmentProviders.selected = shipmentProvider.provider
    config.config.shipmentProvider = shipmentProvider.provider
    if ('ownFleetEnabled' in shipmentProvider) {
      config.config.shipmentProviders.providers =
        config.config.shipmentProviders.providers.map(provider => {
          if (provider.name === shipmentProvider.provider)
            provider.config.ownFleetEnabled = shipmentProvider.ownFleetEnabled
          return provider
        })
    }
  },
  updateManualShipment(manualShipment: boolean) {
    const config = useConfigStore()
    config.config = {
      ...config.config,
      manualShipment
    }
  },
  updateBillingStatus(status: any) {
    const auth = useAuthStore()
    auth.billingStatus = status
  },
  tabOrderingUpdated({
    tabId,
    orderingMode
  }: {
    tabId: string
    orderingMode: string
  }) {
    const tabs = useTabsStore()
    tabs.tabs[tabId].orderingMode = orderingMode
    localDb.setItem('tabs:tabs', tabId, tabs.tabs[tabId])
  }
}

export default events
