<template>
  <div ref="container" class="w-full">
    <tippy
      ref="dropdown"
      :interactive="true"
      :arrow="false"
      trigger="manual"
      placement="bottom-start"
      theme="phoneinput"
      :append-to="() => appendTarget"
      :on-show="showDropdown"
      :on-hide="hideDropdown"
    >
      <input-wrapper
        ref="inputWrapperRef"
        v-bind="$props"
        v-model="localValue"
        type="tel"
        autocomplete="tel"
        @enter="$emit('enter')"
      >
        <template #left>
          <div
            class="flex justify-center items-center cursor-pointer ml-2"
            @click="toggleDropdown"
          >
            <icon
              v-if="flag"
              :name="flag"
              original-color
              class="m-2"
              size="medium"
            />
            <icon
              class="text-n-500 arrow transition"
              name="arrow-down"
              :class="{ 'rotate-180': showingDropdown }"
            />
            <span class="text-gray-500 ml-2"> +{{ country.prefix }}</span>
          </div>
        </template>
      </input-wrapper>
      <template #content>
        <country-selector @select="selectOption" />
      </template>
    </tippy>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, useTemplateRef, watch } from 'vue'
import Icon from '../../Icon.vue'
import InputWrapper from '../InputWrapper.vue'
import CountrySelector from './CountrySelector.vue'
import { type CountrySelection } from './types'
import { type InputPropsWithoutType } from '../inputTypes'
import {
  parsePhoneNumberFromString,
  getCountryCallingCode,
  type CountryCode
} from 'libphonenumber-js'
import { locateCountry, supportedCountries } from '@last/core'
import { Tippy } from 'vue-tippy'

interface Props extends InputPropsWithoutType {
  modelValue?: string
  defaultCountryCode?: CountryCode
}

const props = withDefaults(defineProps<Props>(), {
  defaultCountryCode: undefined,
  modelValue: undefined
})

const inputWrapperRef = useTemplateRef('inputWrapperRef')
const dropdown = useTemplateRef<typeof Tippy>('dropdown')

const emit = defineEmits(['update:modelValue', 'enter'])

const appendTarget = document.body

const prefix = computed(() => {
  if (!country.value.prefix) return ''
  return `+${country.value.prefix}`
})

function getCountry(value: string) {
  if (!value.startsWith('+')) return false
  return supportedCountries.find((code: CountryCode) => {
    try {
      return value
        .replace('+', '')
        .startsWith(getCountryCallingCode(code) as string)
    } catch (_) {
      return false
    }
  })
}

const localValue = computed({
  get: () => {
    if (props.modelValue) {
      return (
        parsePhoneNumberFromString(props.modelValue)?.nationalNumber ||
        props.modelValue.replace(prefix.value, '')
      )
    } else return props.modelValue
  },
  set: value => {
    const countryCode = getCountry(value as string)
    if (countryCode) {
      if (countryCode !== country.value.code) {
        country.value = {
          code: countryCode,
          prefix: getCountryCallingCode(countryCode)
        }
      }
      value = value?.replace('+' + country.value.prefix, '')
    }
    emit('update:modelValue', prefix.value + value)
  }
})

const showingDropdown = ref(false)

const country = ref<CountrySelection>({
  code: 'ES',
  prefix: getCountryCallingCode('ES')
})

async function refreshCountry() {
  const currentCode = parsePhoneNumberFromString(props.modelValue || '')
  if (currentCode?.country) {
    country.value = {
      code: currentCode.country,
      prefix: currentCode.countryCallingCode
    }
    return
  }
  if (props.defaultCountryCode) {
    country.value = {
      code: props.defaultCountryCode,
      prefix: getCountryCallingCode(props.defaultCountryCode)
    }
    return
  }
  const countryCode = await locateCountry()
  country.value = {
    code: countryCode,
    prefix: getCountryCallingCode(countryCode)
  }
}

refreshCountry()

const flag = computed(() => {
  return country.value.code?.toLowerCase()
})

function selectOption(option: CountrySelection) {
  const oldPrefix = country.value.prefix
  country.value = option
  emit(
    'update:modelValue',
    props.modelValue?.replace(
      oldPrefix as string,
      country.value.prefix as string
    )
  )
  showingDropdown.value = false
}

watch(showingDropdown, value => {
  if (value) {
    dropdown.value?.show()
  } else {
    dropdown.value?.hide()
  }
})

function toggleDropdown() {
  showingDropdown.value = !showingDropdown.value
}

function showDropdown() {
  showingDropdown.value = true
}

function hideDropdown() {
  showingDropdown.value = false
}

function focus(): void {
  inputWrapperRef.value?.focusInput()
}

defineExpose({ focus })
</script>

<style>
@import 'tippy.js/dist/tippy.css';

.tippy-box[data-theme~='phoneinput'] {
  background: none !important;
}

.tippy-box[data-theme~='phoneinput'] .tippy-content {
  padding: 0;
}

.tippy-box[data-theme~='phoneinput'][data-animation='fade'][data-state='hidden'] {
  opacity: 0;
}
</style>
