import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref, computed } from 'vue'
import mqtt from '@/sync/mqtt'
import { normalizeEmployees } from '@/normalizr'
import appStorage from '@/drivers/appStorage'
import moment from 'moment'
import { useRouter } from 'vue-router'
import { Employee } from '@/types'
import { useTracker } from '@last/core-ui/paprika'
import { logger, rum } from '@/monitoring'
import {
  authenticate,
  checkVerificationCode,
  getBillingStatus,
  getDemoAuth,
  getEmployees,
  loginSupport,
  requestResetPassword,
  verificationCode
} from '@/api/auth'
import { getLocation } from '@/api/locations'

interface BillingStatus {
  stillInTrial: boolean
  gracePeriodTriggered: boolean
  endsAt?: string
  posEnabled: boolean
  expired?: boolean
}

export const useAuthStore = defineStore(
  'auth',
  () => {
    const tracker = useTracker()
    const router = useRouter()

    // State
    const accessToken = ref<string>()
    const employees = ref<Record<string, Employee>>({})
    const privileges = ref<Record<string, string[]>>({})
    const currentEmployeeId = ref<string>()
    const locationId = ref<string>()
    const locationCoordinates = ref<{ latitude: number; longitude: number }>({
      latitude: 0,
      longitude: 0
    })
    const organizationId = ref<string>()
    const billingStatus = ref<BillingStatus>({
      stillInTrial: false,
      gracePeriodTriggered: false,
      posEnabled: true
    })
    const isLocationManager = ref<boolean>(false)

    const isAuthenticated = computed(() => !!accessToken.value)

    const listEmployees = computed(() => Object.values(employees.value || {}))

    const getPrivileges = computed(() => privileges.value)

    const currentEmployee = computed<Employee | void>(() => {
      if (!currentEmployeeId.value) return
      return employees.value[currentEmployeeId.value]
    })

    const currentEmployeePrivileges = computed(() => {
      if (!currentEmployeeId.value) return []
      return privileges.value[currentEmployeeId.value]
    })

    const anyEmployeeIsManager = computed(() =>
      listEmployees.value.some(employee => employee.isManager)
    )

    const hasPrivilege = (employeeId: string, privilege: string) =>
      !!getPrivileges.value[employeeId]?.includes(privilege)

    const trialExpired = computed(() =>
      Boolean(billingStatus.value.stillInTrial && billingStatus.value.expired)
    )

    const gracePeriodExpired = computed(() =>
      Boolean(
        billingStatus.value.gracePeriodTriggered && billingStatus.value.expired
      )
    )

    const daysLeft = computed(() =>
      moment(billingStatus.value.endsAt).diff(moment(), 'days')
    )

    async function login(
      loginData: { email: string; password: string } | { pin: string }
    ) {
      const response = await authenticate(loginData)
      if (response.locationId) {
        locationId.value = response.locationId
      }
      accessToken.value = response.token
    }

    function askPinForPrivilege(privilege: string) {
      return !listEmployees.value.every(employee =>
        getPrivileges.value[employee.id].includes(privilege)
      )
    }

    function setAccessToken(token: string) {
      accessToken.value = token
    }

    async function supportLogin({
      accessToken,
      selectedOrganizationId,
      selectedLocationId
    }: {
      accessToken: string
      selectedOrganizationId: string
      selectedLocationId: string
    }) {
      const supportUserIsValid = await loginSupport(accessToken)
      if (supportUserIsValid) {
        setAccessToken(accessToken)
        organizationId.value = selectedOrganizationId
        locationId.value = selectedLocationId
        return true
      }
      return false
    }

    async function refreshCurrentLocation() {
      const location = await getLocation()
      setLocationCoordinates(location)
      tracker.identify({ id: location.id, name: location.name })
      rum.identify({ id: location.id, name: location.name })
      logger.identify({ id: location.id, name: location.name })
    }

    function selectEmployee(employeeId: string) {
      currentEmployeeId.value = employeeId
    }

    async function refreshEmployees() {
      const employeesData = await getEmployees()
      const employeeData = normalizeEmployees(employeesData) as {
        [key: string]: {
          [key: string]: Employee
        }
      }
      employees.value = employeeData.employees
      const privilegeData = employeesData.reduce(
        (acc, employee) => {
          acc[employee.id] = employee.privileges
          return acc
        },
        {} as { [key: string]: string[] }
      )
      privileges.value = privilegeData
    }

    async function refreshBillingStatus() {
      billingStatus.value = await getBillingStatus()
    }

    function validateEmployeePinWithPrivilege({
      pin,
      privilege
    }: {
      pin: string
      privilege: string
    }) {
      const ret = { pin: false, acces: false }
      const currentEmployee = currentEmployeeId.value
        ? employees.value[currentEmployeeId.value]
        : null

      if (currentEmployee && pin === currentEmployee.accessPin) {
        ret.pin = true
        if (currentEmployeePrivileges.value.includes(privilege)) {
          ret.acces = true
        }
      } else {
        listEmployees.value.forEach(employee => {
          if (
            employee.accessPin === pin &&
            hasPrivilege(employee.id, privilege)
          ) {
            ret.acces = true
            currentEmployeeId.value = employee.id
          }
        })
      }
      return ret
    }

    function setLocationCoordinates(location: {
      id: string
      latitude: number
      longitude: number
    }) {
      locationId.value = location.id
      if (location.latitude && location.longitude) {
        locationCoordinates.value = {
          latitude: location.latitude,
          longitude: location.longitude
        }
      }
    }

    function selectLocation(location: {
      id: string
      latitude: number
      longitude: number
    }) {
      setLocationCoordinates(location)
      mqtt.subscribe(location.id)
    }

    async function logout() {
      accessToken.value = undefined
      locationId.value = undefined
      await appStorage.clear()
      router.push({ name: 'login' })
    }

    async function getDemoAuthData() {
      const demoData = await getDemoAuth()
      const { locationId: demoLocationId, organizationId: demoOrganizationId } =
        demoData
      locationId.value = demoLocationId
      organizationId.value = demoOrganizationId
      accessToken.value = 'demoToken'
    }

    function setBillingStatus(status: BillingStatus) {
      billingStatus.value = status
    }

    async function sendVerificationCode(email: string): Promise<string> {
      try {
        return verificationCode(email)
      } catch {
        return ''
      }
    }

    async function validateVerificationCode(
      id: string,
      code: string
    ): Promise<boolean> {
      try {
        return (await checkVerificationCode(id, code)).result
      } catch {
        return false
      }
    }

    async function resetPassword(
      id: string,
      password: string
    ): Promise<boolean> {
      try {
        await requestResetPassword(id, password)
        return true
      } catch {
        return false
      }
    }

    return {
      accessToken,
      employees,
      privileges,
      currentEmployeeId,
      locationId,
      locationCoordinates,
      organizationId,
      billingStatus,
      isLocationManager,

      isAuthenticated,
      listEmployees,
      getPrivileges,
      currentEmployee,
      currentEmployeePrivileges,
      anyEmployeeIsManager,
      trialExpired,
      gracePeriodExpired,
      daysLeft,

      login,
      askPinForPrivilege,
      setAccessToken,
      supportLogin,
      refreshCurrentLocation,
      selectEmployee,
      refreshEmployees,
      refreshBillingStatus,
      validateEmployeePinWithPrivilege,
      selectLocation,
      logout,
      getDemoAuthData,
      setBillingStatus,
      sendVerificationCode,
      validateVerificationCode,
      resetPassword,
      hasPrivilege
    }
  },
  { persist: true }
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
