import api from '@/api'
import { Reservations, Tab, TabPromotion } from '@/types'
import { RemoteEvent } from '@/types/events'
import { VirtualBrand } from '@/types/virtualBrands'

export function postEvent(event: any) {
  return api
    .post<{
      id: number
    }>('/events', event)
    .then(res => res.data)
}

export function sync(currentEventId?: number) {
  return api
    .get<{
      currentEventId: number
      events?: RemoteEvent[]
      tabs: Tab[]
      virtualBrandsClosingTimes: VirtualBrand[]
      reservations: Reservations
      tabPromotions: Record<string, TabPromotion[]>
      startedShiftsWithCashAmount: string[]
      startedShifts: string[]
      shift: {
        enabled: boolean
        started: boolean
      }
      shiftsEnabled: boolean
    }>('/sync', { ...(currentEventId && { currentEventId }) })
    .then(res => res.data)
}
