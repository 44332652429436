import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia'
import { Tab } from '@/types'
import { computed, ref, watch, toRaw } from 'vue'
import { useTabsStore } from '@/store/tabs'
import { useRoute, useRouter } from 'vue-router'
import { useKitchenOrders } from '@/composables/useKitchenOrders.ts'
import type { CalculatorPayload } from '@/components/Calculator/CalculatorUtils.ts'
import { useCheckout } from '@/composables/useCheckout.ts'
import { useConfigStore } from '@/store/config.ts'

export const useFastPOS = defineStore('fastPOS', () => {
  const router = useRouter()
  const route = useRoute()

  const configStore = useConfigStore()
  const { config } = storeToRefs(configStore)

  const tabsStore = useTabsStore()
  const { tabs } = storeToRefs(tabsStore)
  const fastTabId = ref<string>('')

  function createFastTab(): string | undefined {
    if (fastTabId.value) {
      return
    }

    let newTab = tabsStore.formatTab({ tab: {} })
    const tabId = newTab.id

    newTab = {
      ...newTab,
      name: 'FastPOS',
      shared: [],
      seats: [],
      bills: [],
      kitchenOrders: []
    }

    tabs.value[tabId] = newTab
    fastTabId.value = tabId

    return tabId
  }

  function loadFastPOS(): void {
    if (!fastTabId.value) {
      createFastTab()
    }

    router.replace({
      name: 'fastPOS',
      params: {
        tabId: fastTabId.value
      }
    })
  }

  const isFastPOS = computed(() => {
    return route.name === 'fastPOS'
  })

  watch(
    () => route.name,
    () => {
      if (!['fastPOS', 'checkout'].includes(route.name)) {
        if (
          fastTabId.value &&
          Object.entries(tabs.value[fastTabId.value]?.shared)?.length === 0
        ) {
          delete tabs.value[fastTabId.value]
          fastTabId.value = undefined
        } else {
          fastTabId.value = undefined
        }
      } else if (route.name === 'fastPOS' && !route.params.tabId) {
        loadFastPOS()
      }
    }
  )

  async function doFastPayment(method: 'card' | 'cash') {
    if (!fastTabId.value) return

    // Create kitchenOrders for tab
    if (config.value.enableKitchenOrders) {
      const { sendKitchenOrder } = useKitchenOrders(fastTabId.value)
      await sendKitchenOrder('all')
    }

    // Process payment (card|cash)
    const calculatorValues = ref<CalculatorPayload>({
      toPay: 0,
      pending: 0,
      change: 0,
      tip: 0
    })
    const { charge, currentBill, selectPaymentMethod } = useCheckout(
      fastTabId.value,
      'fastPOS',
      calculatorValues
    )
    selectPaymentMethod(method)
    calculatorValues.value.toPay = currentBill.value?.pending
    await charge()

    // refresh FastPOS
    fastTabId.value = undefined
    loadFastPOS()
  }

  function addTabToSystem(): void {
    if (!fastTabId.value) return

    const currentFastTab = toRaw(tabs.value[fastTabId.value]) as Tab

    if (currentFastTab && currentFastTab?.shared?.length > 0) return

    if (currentFastTab.seats) delete currentFastTab.seats
    if (currentFastTab.shared) delete currentFastTab.shared
    if (currentFastTab.bills) delete currentFastTab.bills
    if (currentFastTab.kitchenOrders) delete currentFastTab.kitchenOrders

    const newTabId = tabsStore.openTab({
      tab: currentFastTab
    })

    router.replace({
      name: 'fastPOS',
      params: {
        tabId: newTabId
      }
    })

    return
  }

  function doCheckout(): void {
    router.push({
      name: 'checkout',
      params: {
        tabId: fastTabId.value
      }
    })
  }

  return {
    fastTabId,
    isFastPOS,
    loadFastPOS,
    doFastPayment,
    doCheckout,
    addTabToSystem
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFastPOS, import.meta.hot))
}
