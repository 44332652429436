<template>
  <l-modal
    :title="$t('reservations.new')"
    :open="open"
    :button-text="$t('reservations.accept')"
    :button-enabled="isComplete"
    @action="save()"
    @close="$emit('close')"
  >
    <div class="flex items-center justify-between gap-4">
      <l-field :label="$t('reservations.name')" class="w-full">
        <l-input
          v-model="internalReservation.name"
          :placeholder="$t('reservations.name')"
          icon="user"
        />
      </l-field>
      <l-field :label="$t('reservations.surname')" class="w-full">
        <l-input
          v-model="internalReservation.surname"
          :placeholder="$t('reservations.surname')"
        />
      </l-field>
    </div>

    <l-field :label="$t('reservations.phone-number')">
      <l-phone-input
        v-model="internalReservation.phoneNumber"
        icon="phone"
        :placeholder="$t('reservations.phone-number')"
        :wrong-value="validating && !validPhoneNumber"
        :default-country-code="config.locationCountryCode"
      />
    </l-field>

    <div class="flex items-center justify-between gap-4">
      <l-field :label="$t('reservations.diners')" class="w-full">
        <l-input
          v-model="internalReservation.diners"
          :placeholder="$t('reservations.diners-placeholder')"
          type="number"
        />
      </l-field>
      <l-field class="w-full" :label="$t('reservations.time')">
        <l-date-picker
          v-model="internalReservation.dateTime"
          :placeholder="$t('reservations.time-placeholder')"
          only-future
        />
      </l-field>
    </div>

    <l-field :label="$t('reservations.table')">
      <l-input
        :model-value="
          !isReservationEmpty ? printTables(internalReservation.tables!) : null
        "
        icon="external"
        icon-position="right"
        :placeholder="$t('reservations.no-table')"
        read-only
        @click="$emit('selectTable', internalReservation)"
      />
    </l-field>

    <l-field :label="$t('reservations.comments')">
      <l-input
        v-model="internalReservation.customerComments"
        type="textarea"
        :placeholder="$t('reservations.comments-placeholder')"
      />
    </l-field>
  </l-modal>
</template>

<script setup lang="ts">
import {
  LModal,
  LInput,
  LDatePicker,
  LPhoneInput,
  LField
} from '@last/core-ui/paprika'
import { v4 as uuid } from 'uuid'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { useTablesStore } from '@/store/tables'
import { useConfigStore } from '@/store/config'
import { useReservationsStore } from '@/store/reservations'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { watch } from 'vue'
import { onMounted } from 'vue'
import { ref } from 'vue'
import { Reservation } from '@/types'

const props = withDefaults(
  defineProps<{
    reservation: Reservation
    open: boolean
  }>(),
  {
    open: false
  }
)
const emit = defineEmits(['close', 'selectTable'])

const tablesStore = useTablesStore()
const configStore = useConfigStore()
const reservationsStore = useReservationsStore()

const { createReservation, editReservation } = reservationsStore
const { tables } = storeToRefs(tablesStore)
const { config } = storeToRefs(configStore)

const internalReservation = ref<Partial<Reservation>>({})
const validating = ref(false)

const isComplete = computed(() => {
  return (
    !!internalReservation.value?.name &&
    !!internalReservation.value?.diners &&
    !!internalReservation.value?.dateTime
  )
})

const validPhoneNumber = computed(() => {
  const parsed = parsePhoneNumberFromString(
    internalReservation.value?.phoneNumber || ''
  )
  return parsed?.isValid()
})

const isReservationEmpty = computed(() => {
  return !internalReservation.value?.tables?.length
})

watch(props.reservation, reservation => {
  internalReservation.value = reservation
})

onMounted(() => {
  internalReservation.value = {
    ...props.reservation,
    phoneNumber:
      props.reservation.phoneNumber ||
      internalReservation.value?.phoneNumber ||
      ''
  }
})

function formatNumber(phoneNumber: string) {
  const parsedNumber = parsePhoneNumberFromString(phoneNumber)
  return parsedNumber ? parsedNumber.number : phoneNumber
}

function save() {
  validating.value = true
  if (!validPhoneNumber.value) return
  let reservation = {
    ...internalReservation.value!,
    phoneNumber: formatNumber(internalReservation.value.phoneNumber!)
  }
  if (reservation.id) {
    editReservation(reservation as Reservation)
  } else {
    reservation = {
      id: uuid(),
      cancelled: false,
      ...reservation
    }
    createReservation(reservation as Reservation)
  }
  emit('close')
}

function printTables(reservedTables: string[]) {
  return reservedTables.map(table => tables.value[table].name).toString()
}
</script>
