<template>
  <div class="bg-n-600 py-7 px-6 text-n-0 rounded-2xl">
    <div class="grid grid-cols-[repeat(2,minmax(0,1fr))_2.25rem] gap-4">
      <div class="">
        <div
          class="font-heading text-base font-medium overflow-hidden text-ellipsis whitespace-nowrap"
        >
          {{ reservation.name }} {{ reservation.surname }}
        </div>
        <div class="font-body text-xs text-n-200">
          {{ t('reservations.name') }}
        </div>
      </div>
      <div>
        <div
          class="font-heading text-base font-medium overflow-hidden text-ellipsis whitespace-nowrap"
        >
          {{ formattedTableNames }}
        </div>
        <div class="font-body text-xs text-n-200">
          {{ t('reservations.table') }}
        </div>
      </div>
      <div class="flex">
        <l-tooltip
          :interactive="false"
          :arrow="true"
          :trigger="reservation.customerComments ? 'click' : 'manual'"
          placement="bottom"
          :append-to="() => appendTarget"
        >
          <div
            :class="{
              'bg-v-300 text-n-0 cursor-pointer': reservation.customerComments,
              'text-v pointer-events-none': !reservation.customerComments
            }"
            class="flex items-center justify-center border box-border border-v-300 rounded-full w-9 h-9"
          >
            <icon name="chat"></icon>
          </div>
          <template #content>
            {{ reservation.customerComments }}
          </template>
        </l-tooltip>
      </div>
      <div>
        <div
          class="font-heading text-base font-medium overflow-hidden text-ellipsis whitespace-nowrap"
        >
          {{ reservation.phoneNumber }}
        </div>
        <div class="font-body text-xs text-n-200">
          {{ t('reservations.phone-number') }}
        </div>
      </div>
      <div class="flex flex-col">
        <div
          class="font-heading text-base font-medium overflow-hidden text-ellipsis whitespace-nowrap"
        >
          {{ reservation.source || $t('reservations.restaurant') }}
        </div>
        <div class="font-body text-xs text-n-200">
          {{ t('reservations.source') }}
        </div>
      </div>
      <div
        v-if="!linkMode"
        class="p-2 cursor-pointer invisible sm:visible"
        @click="$emit('edit')"
      >
        <icon name="pen" class="text-v"></icon>
      </div>
      <div v-else></div>
      <div>
        <div
          class="font-heading text-base font-medium overflow-hidden text-ellipsis whitespace-nowrap"
        >
          {{ formattedDate }}
        </div>
        <div class="font-body text-xs text-n-200">
          {{ t('reservations.time') }}
        </div>
      </div>
      <div class="flex flex-col">
        <div
          class="font-heading text-base font-medium overflow-hidden text-ellipsis whitespace-nowrap"
        >
          {{ reservation.diners ?? '-' }}
        </div>
        <div class="font-body text-xs text-n-200">
          {{ t('reservations.diners') }}
        </div>
      </div>
      <div
        v-if="!linkMode"
        class="p-2 cursor-pointer invisible sm:visible"
        @click="$emit('delete')"
      >
        <icon name="trash" class="text-v"></icon>
      </div>
      <div v-else>
        <l-button
          icon="link"
          size="small"
          @click="$emit('link', reservation.id)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon, LButton, LTooltip } from '@last/core-ui/paprika'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import { useTablesStore } from '@/store/tables'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { Reservation } from '@/types'

const props = defineProps<{
  reservation: Reservation
  linkMode: boolean
}>()

defineEmits(['edit', 'delete', 'link'])

const { t } = useI18n()

const tablesStore = useTablesStore()
const { tables: storeTables } = storeToRefs(tablesStore)

const appendTarget = document.body

const formattedTableNames = computed(
  () =>
    (props.reservation.tables ?? [])
      .map(table => storeTables.value[table].name)
      .toString() ||
    props.reservation.zone ||
    t('reservations.unassigned')
)

const formattedDate = computed(() => {
  const date = moment(props.reservation.dateTime)
  if (date.isSame(moment(), 'day')) {
    return t('reservations.today') + ' - ' + date.format('HH:mm')
  }
  return moment(props.reservation.dateTime).format('DD/MM - HH:mm')
})
</script>
